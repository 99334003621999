import OnlineStatusSnackbar from "layouts/onlineStatusSnackbar/onlineStatusSnackbar";
import GoogleIcon from "@mui/icons-material/Google";
import MDBox from "components/MDBox";
import { UserContext } from "context/UserContext";
import MDButton from "components/MDButton";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Alert, Snackbar } from "@mui/material";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg.jpg";
import { UserAuth } from "../../../context/AuthContext";

function Basic() {
  const navigate = useNavigate();
  const { error } = useContext(UserContext);

  console.log(error);

  useEffect(() => {
    document.title = "Log In - RadDB";
  }, []);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const { signInError, logout, user, signInUsingGoogle } = UserAuth();

  console.log(signInError);

  const [state, setState] = useState({
    openToast: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, openToast } = state;

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, openToast: false });
  };

  console.log(user && error === "Missing or insufficient permissions.");

  useEffect(() => {
    if (user && error === "Missing or insufficient permissions.") {
      setOpen(false);
      setLoading(false);
      logout();
      navigate("/sign-in", { replace: true });
    }

    if (user && error !== "Missing or insufficient permissions.") {
      navigate("/", { replace: true });
    }
  }, [user, error]);

  useEffect(() => {
    if (error) {
      setState({ ...state, openToast: true });
    }
  }, [error]);

  useEffect(() => {
    if (signInError) {
      setLoading(false);
      setOpen(false);
    }
  }, [signInError]);
  return (
    <BasicLayout image={bgImage}>
      <OnlineStatusSnackbar />

      <Snackbar
        // autoHideDuration={4000}
        anchorOrigin={{ vertical, horizontal }}
        open={openToast}
        onClose={handleToastClose}
        message="I love snacks"
        key={vertical + horizontal}
      >
        <Alert onClose={handleToastClose} severity="error">
          Your Google Account does not have an access token for this application. Please contact
          Aimi Firdhaus Shafie at aimifirdhausshafie@moh.gov.my to obtain an access token.
        </Alert>
      </Snackbar>

      <MDBox pt={3} pb={3} px={{ xs: 3, sm: 4, md: 7 }}>
        {open ? (
          <MDButton
            variant="outlined"
            fullWidth
            color="white"
            disabled={loading}
            onClick={() => {
              // setCurrentError(null);
              setLoading(true);
              handleToggle();
              signInUsingGoogle();
              setState({ ...state, openToast: false });
            }}
            sx={{
              borderRadius: "23px",
            }}
            endIcon={
              <CircularProgress
                style={{ display: open ? "inline" : "none" }}
                disableShrink
                color="inherit"
                size="1rem"
              />
            }
          >
            <GoogleIcon sx={{ mr: 2 }} /> Log In With Google
          </MDButton>
        ) : (
          <MDButton
            variant="outlined"
            fullWidth
            color="white"
            disabled={loading}
            onClick={() => {
              // setCurrentError(null);
              setLoading(true);
              handleToggle();
              signInUsingGoogle();
              setState({ ...state, openToast: false });
            }}
            sx={{
              borderRadius: "23px",
            }}
          >
            <GoogleIcon sx={{ mr: 2 }} /> Log In With Google
          </MDButton>
        )}
      </MDBox>
    </BasicLayout>
  );
}

export default Basic;
