import { createContext, useContext, useEffect, useState, useMemo } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
} from "firebase/auth";
// import { auth } from "../firebase-config";

import * as firebase from "../firebase-config"; // import firebase object

const { auth } = firebase; // destructure auth from firebase object

const UserContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [token, setToken] = useState("");
  const [signInError, setSignInError] = useState("");
  const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password);

  const signInUsingGoogle = () => {
    const authG = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(authG, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        setToken(credential.accessToken);

        // The signed-in user info.
        // const { user } = result;
        setUser(result.user);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        console.log(error);
        setSignInError(error);
      });
  };

  const logout = () => signOut(auth);

  const memoValue = useMemo(
    () => ({ token, signIn, user, logout, signInUsingGoogle, signInError }),
    [token, signIn, user, logout, signInUsingGoogle, signInError]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  return <UserContext.Provider value={memoValue}>{children}</UserContext.Provider>;
}

export function UserAuth() {
  return useContext(UserContext);
}
