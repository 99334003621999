import { createContext, useState, useEffect, useMemo } from "react";
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../firebase-config";

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [unfilteredUsers, setUnfilteredUsers] = useState([]);
  const [users, setUsers] = useState([]);
  console.log(users);
  const [user, setUser] = useState("");
  console.log(user);
  const [error, setError] = useState(null);
  console.log(error);
  const [currentUser, setCurrentUser] = useState("");
  console.log(currentUser);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (u) => {
      if (u) {
        setUser(u.email);
        console.log(u);
        // User is logged in
        if (
          u.email === "fznuklear.ikn@gmail.com" ||
          u.email === "aimifirdhausshafie@gmail.com" ||
          u.email === "fakhrurrazi@moh.gov.my"
        ) {
          setCurrentUser("Institut Kanser Negara");
        } else if (u.email === "fastlane.fd@gmail.com") {
          setCurrentUser("Hospital Kuala Lumpur");
        } else if (u.email === "demo@raddosedb.com") {
          setCurrentUser("Demo Profile");
        } else if (u.email === "nuclearmedhpp@moh.gov.my") {
          setCurrentUser("Hospital Pulau Pinang");
        } else if (u.email === "nuclearmedhsa@moh.gov.my") {
          setCurrentUser("Hospital Sultanah Aminah");
        } else if (u.email === "nuclearmedhus@moh.gov.my") {
          setCurrentUser("Hospital Umum Sarawak");
        } else if (u.email === "nuclearmedhwkks@moh.gov.my") {
          setCurrentUser("Hospital Wanita & Kanak-Kanak Sabah");
        }
        // else if (u.email === "aimifirdhausshafie@gmail.com") {
        //   setCurrentUser("Limbo");
        // }
      } else {
        // User is logged out
        setUser("");
        setCurrentUser("");
        setUsers([]); // Reset the users state to an empty array
      }
    });

    return () => unsubscribeAuth(); // Unsubscribe from the auth state changes
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "users"),
      where("hosp", "==", currentUser),
      orderBy("timeStampReg", "desc"),
      orderBy("name")
    );
    const unsubscribeUsers = onSnapshot(q, (snapshot) =>
      setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );

    const p = query(
      collection(db, "locations"),
      where("hosp", "==", currentUser),
      orderBy("timeStampReg", "desc"),
      orderBy("locationName")
    );

    const unsubscribeLocations = onSnapshot(p, (snapshot) =>
      setLocations(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    );

    return () => {
      unsubscribeUsers(); // Unsubscribe from the users snapshot
      unsubscribeLocations();
    };
  }, [currentUser]);

  useEffect(() => {
    const q = query(collection(db, "users"), orderBy("timeStampReg", "desc"), orderBy("name"));

    if (user) {
      console.log("test");
      const unsubscribeUsers = onSnapshot(
        q,
        (snapshot) => {
          setError(null);
          setUnfilteredUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        },
        (e) => {
          setError(e.message);
        }
      );

      return () => unsubscribeUsers(); // Unsubscribe from the users snapshot
    }

    setError(null);

    return undefined;
  }, [user, error]);

  const memoedUsers = useMemo(
    () => ({
      users,
      unfilteredUsers,
      locations,
      error,
      currentUser,
    }),
    [users, unfilteredUsers, locations, error, currentUser]
  );

  return <UserContext.Provider value={memoedUsers}>{children}</UserContext.Provider>;
}
