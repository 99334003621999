import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "context/UserContext";
import { UserAuth } from "./context/AuthContext";

function ProtectedRoute({ children }) {
  // const navigate = useNavigate();
  const { error } = useContext(UserContext);
  const { user } = UserAuth();
  // console.log(user);
  console.log(error);
  // console.log(error === "Missing or insufficient permissions.");

  // const [currentError, setCurrentError] = useState(null);

  // useEffect(() => {
  //   if (error) {
  //     setCurrentError(error);
  //   }
  // }, [error]);

  if (!user || (user && error === "Missing or insufficient permissions.")) {
    return <Navigate to="/sign-in" replace />;
  }

  return children;
}

export default ProtectedRoute;
