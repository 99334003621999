/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Avatar from "@mui/material/Avatar";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { styled } from "@mui/material/styles";
// import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
// import NotificationItem from "examples/Items/NotificationItem";

// import logo from "assets/images/logo.svg";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { UserAuth } from "../../../context/AuthContext";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "../../../context";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    // anchorOrigin={{
    //   vertical: "bottom",
    //   horizontal: "right",
    // }}
    // transformOrigin={{
    //   vertical: "top",
    //   horizontal: "right",
    // }}
    {...props}
  />
))(() => ({
  "& .MuiPaper-root": {
    borderRadius: 30,
  },
}));

function DashboardNavbar({ absolute, light, isMini }) {
  const [loading, setLoading] = useState(false);
  const [showLogOutCircularProgress, setShowLogOutCircularProgress] = useState(false);
  const { user, logout } = UserAuth();
  console.log(user);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  // const [openMenu, setOpenMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const handleLogout = async () => {
    setShowLogOutCircularProgress(true);

    try {
      await logout();
      navigate("/sign-in", { replace: true });
      console.log("You are logged out");
    } catch (err) {
      setError(err.message);
      console.log(error);
    }
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenProfileMenu = (event) => setOpenProfileMenu(event.currentTarget);
  const handleCloseProfileMenu = () => setOpenProfileMenu(false);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  // const handleCloseMenu = () => setOpenMenu(false);

  // Render the profile menu
  const renderProfileMenu = () => (
    <StyledMenu
      anchorEl={openProfileMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openProfileMenu)}
      onClose={handleCloseProfileMenu}
      sx={{ mt: 2 }}
    >
      <MDBox sx={{ padding: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Avatar alt={user?.displayName} src={user?.photoURL} />
        <MDTypography sx={{ mt: 2 }} variant="h6">
          Hai, {user?.displayName?.replace(/\s*\(.*?\)\s*/g, "")}!
        </MDTypography>
        <MDTypography sx={{ mt: 1 }} variant="body">
          {user?.email}
        </MDTypography>
        {showLogOutCircularProgress ? (
          <MDButton
            startIcon={<PowerSettingsNewIcon />}
            disabled={loading}
            endIcon={
              <CircularProgress
                style={{
                  display: showLogOutCircularProgress ? "inline" : "none",
                }}
                disableShrink
                color="error"
                size="1rem"
              />
            }
            sx={{ mt: 3, borderRadius: "23px" }}
            color="error"
            variant="outlined"
            onClick={handleLogout}
          >
            Log Out
          </MDButton>
        ) : (
          <MDButton
            startIcon={<PowerSettingsNewIcon />}
            sx={{ mt: 3, borderRadius: "23px" }}
            color="error"
            variant="outlined"
            onClick={() => {
              setLoading(true);
              handleLogout();
            }}
          >
            Log Out
          </MDButton>
        )}
      </MDBox>
    </StyledMenu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            {/* <MDBox display="flex" mr="20px">
              {miniSidenav && <img src={logo} alt="logo" width="15px" />}
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="error"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenProfileMenu}
              >
                {/* <Icon sx={iconsStyle}>account_circle</Icon> */}
                <Avatar
                  alt={user?.displayName}
                  src={user?.photoURL}
                  sx={{ width: 20, height: 20 }}
                />
              </IconButton>
              {renderProfileMenu()}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton>
              {renderMenu()} */}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
